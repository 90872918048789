angular.module('fingerink')
    .service('changeSignatureModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/changeSignatureModal.tpl.html',
                controller: 'changeSignatureModalCtrl',
                controllerAs: 'controller',
                animation: false,
                size: 'lg'
            });

            return this.modalInstance;
        };

    })
    .controller('changeSignatureModalCtrl', function changeSignatureModalCtrl($scope, session, userService, $timeout, $uibModalInstance, $translate, signModal) {

        var that = this;

        $scope.selectedIndex = 0;

        that.user = session.get().user;
        that.text = that.user.name;
        that.fonts = ['Satisfy', 'Sacramento', 'Parisienne', 'Mrs Saint Delafield', 'Miss Fajardose', 'Lovers Quarrel', 'League Script', 'Great Vibes', 'Dawning of a New Day'];

        $.get('https://fonts.googleapis.com/css?family=Dawning+of+a+New+Day|Great+Vibes|League+Script|Lovers+Quarrel|Miss+Fajardose|Mrs+Saint+Delafield|Parisienne|Sacramento|Satisfy', function () {
            that.loaded = true;
        });

        that.close = function () {
            $uibModalInstance.close();
        };


        that.guardarFromBase64 = function () {
            that.saving = true;
            var croppedCanvas = document.createElement('canvas');
            var croppedCtx = croppedCanvas.getContext('2d');
            // croppedCtx.fillStyle = 'white';
            croppedCtx.font = '200px ' + that.fontSelected;

            croppedCtx.canvas.width = croppedCtx.measureText(that.text).width + 100;
            croppedCtx.canvas.height = 400;


            //croppedCtx.fillStyle = 'white';
            croppedCtx.font = '200px ' + that.fontSelected;

            croppedCtx.fillText(that.text, 50, 250);

            userService.uploadImageURLBase64(croppedCanvas.toDataURL()).then(function (response) {
                swal($translate.instant("Firma cambiada"), $translate.instant("Se ha modificado su firma correctamente"), "success");
                $uibModalInstance.close(response.data);
            });
        };




        that.dibujarFirmaModal = function () {
            function mostrarCtrl($rootScope, $scope, $uibModalInstance, $window, $timeout) {
                $scope.ok = function () {
                    $uibModalInstance.close($scope.dataurl);
                };

                $scope.close = function () {
                    $uibModalInstance.close();
                };

                $scope.mostrar = false;

                function calcular() {
                    //var WinHeight = window.innerHeight || Math.max(document.documentElement.clientHeight, document.body.clientHeight);
                    var WinHeight = window.innerHeight || Math.max(document.documentElement.clientHeight, document.body.clientHeight);
                    var WinWidth = window.innerWidth || Math.max(document.documentElement.clientWidth, document.body.clientWidth);

                    $scope.w = Math.min(1000, WinWidth - 40);
                    $scope.h = Math.min(500, WinHeight  - 140);
                    $timeout(function () {
                        $scope.mostrar = true;
                        $scope.$digest();
                    }, 100);
                }
                calcular();


                angular.element($window).bind('resize', function () {
                    $scope.mostrar = false;
                    calcular();
                });
            }


            signModal.openModal().result.then(function (result) {
                if (result) {
                    that.dataurl = result;
                }
            });


        };




        that.ok = function (a) {
            that.saving = true;
            userService.uploadImageURLBase64(that.dataurl).then(function (response) {
                swal($translate.instant("Firma cambiada"), $translate.instant("Se ha modificado su firma correctamente"), "success");
                $uibModalInstance.close(response.data);
            });
        };


        that.okFichero = function () {
            if (that.files && that.files[0]) {
                that.saving = true;
                userService.uploadImageURLFile(that.files[0]).then(function (response) {
                    swal($translate.instant("Firma cambiada"), $translate.instant("Se ha modificado su firma correctamente"), "success");
                    $uibModalInstance.close(response.data);
                });
            }
        };
        $timeout(function () {
            that.dropifyLoaded = true;
            $('.dropify').dropify({
                messages: {
                    'default': $translate.instant('Arrastra aquí el documento o haz click'),
                    'replace': $translate.instant('Arrastra aquí o haz click para reemplazar'),
                    'remove': $translate.instant('Remove'),
                    'error': $translate.instant('Ups. Ha ocurrido algún error.')
                }
            });
        }, 100);





    });
